.header {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: 410px;
	padding: 1rem 2rem 1.5rem;
	background: var(--color-white);
	border-radius: 0 0 1rem 1rem;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}
