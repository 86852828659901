/* PostCSS Normalize */
@import-normalize;

/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@500&display=swap');

/* CSS Variables */
:root {
	--color-red: #ed1c24;
	--color-blue: #466ea3;
	--color-green: #52a123;
	--color-white: #fff;
	--color-light-grey: #ecedef;
	--color-med-grey: #738692;
	--color-dark-grey: #47505f;

	--color-red-dark: #971418;
	--color-blue-dark: #2a4c77;
	--color-green-dark: #254d0e;
}

/**
 * Fix fonts that render as bold in Firefox
 */

html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 400;
}

/**
   * Firefox specific rule
   */

@-moz-document url-prefix() {
	body {
		font-weight: lighter !important;
	}
}

/* CSS Reset */
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
	scroll-behavior: smooth;
	font-family: 'Josefin Sans', sans-serif;
	background: var(--color-light-grey);
}

html,
body,
#root {
	height: 100%;
}

/* Wrapper */
.wrapper {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100%;
	background: var(--color-light-grey);
}

button,
.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background: var(--color-red);
	color: var(--color-white);
	padding: 1rem 1.5rem 1rem;
	font-size: 1.6rem;
	font-family: inherit;
	line-height: 1;
	border-radius: 3rem;
	font-weight: bold;
	transition: all 0.2s ease;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media only screen and (min-width: 45em) {
		font-size: 2.4rem;
		padding: 1.5rem 2.5rem 1.25rem;
	}

	&:hover {
		cursor: pointer;
	}

	svg {
		position: relative;
		fill: currentColor;
		width: 2rem;
		height: 2rem;
		margin-left: 1rem;
	}

	&.button--red {
		border: 4px solid var(--color-red);
		background: var(--color-red);
		color: var(--color-white);

		&:hover {
			opacity: 1;
			background: var(--color-red-dark);
		}

		&.active {
			opacity: 1;
			border: 4px solid var(--color-red-dark);
		}
	}

	&.button--blue {
		border: 4px solid var(--color-blue);
		background: var(--color-blue);

		&:hover {
			opacity: 1;
			background: var(--color-blue-dark);
		}

		&.active {
			opacity: 1;
			border: 4px solid var(--color-blue-dark);
		}
	}

	&.button--green {
		border: 4px solid var(--color-green);
		background: var(--color-green);

		&:hover {
			opacity: 1;
			background: var(--color-green-dark);
		}

		&.active {
			opacity: 1;
			border: 4px solid var(--color-green-dark);
		}
	}

	&.button--grey {
		border: 4px solid var(--color-dark-grey);
		background: var(--color-dark-grey);
	}

	&.button--settings {
		background: #ecedef;
		color: var(--color-dark-grey);
	}

	&:disabled,
	&[disabled] {
		opacity: 0.5;
		background: gray;
		border: 4px solid grey;
		color: white;
		pointer-events: none;
	}
}
