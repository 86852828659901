.timer {
	width: 90%;
	max-width: 700px;
	margin: 2.4rem 0;
	background: var(--color-white);
	border-radius: 1rem;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

.timer__wrapper {
	padding: 3rem;
}

.timer__controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;

	@media only screen and (min-width: 45em) {
		flex-direction: row;
		justify-content: space-between;
	}

	button {
		opacity: 0.66;

		&:not(:last-child) {
			margin-bottom: 1.5rem;

			@media only screen and (min-width: 45em) {
				margin-bottom: 0;
			}
		}
	}
}

.timer__start {
	position: relative;
	left: 50%;
	top: -2.5rem;
	transform: translateX(-50%);
	box-shadow: 0 0 1rem rgba(black, 0.3);
}

.timer__clock {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--color-red);
	padding: 2rem 0 3rem;
	border-radius: 1.25rem;
	text-align: center;
	color: var(--color-white);
	font-size: 20vmin;
	line-height: 1;
	font-family: 'Fira Mono', monospace;

	@media only screen and (min-width: 45em) {
		padding: 2rem 0 1.5rem;
		font-size: 18rem;
		padding: 3.5rem 0;
		letter-spacing: -0.25rem;
	}

	&.timer__clock--pomodoro {
		background: var(--color-red);
	}
	&.timer__clock--short-break {
		background: var(--color-blue);
	}
	&.timer__clock--long-break {
		background: var(--color-green);
	}
}

.timer__meta {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-dark-grey);

	@media only screen and (min-width: 45em) {
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	span {
		font-size: 15px;
	}

	> div:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.timer__settings,
.timer__rounds {
	display: flex;
	align-items: center;
	font-size: 2.4rem;

	.round {
		opacity: 0.25;
		margin-left: 1.25rem;

		&.active {
			opacity: 1;
		}
	}

	svg {
		width: 30px;
		height: 30px;

		@media only screen and (min-width: 45em) {
			width: 60px;
			height: 60px;
		}
	}
}
